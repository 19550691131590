import { cloneDeep } from "lodash-es";
import { flatten, unflatten } from "safe-flat";
import { AddedStep, ChangedStep, RemovedStep } from "@/flow/types";

export function useSyncInputData({
  flow, changedStep, removedStep, addedStep,
}: {
  flow: any,
  changedStep?: ChangedStep,
  removedStep?: RemovedStep,
  addedStep?: AddedStep,
}) {
  const clonedFlow = cloneDeep(flow);
  const splitKey = "[reagent]";
  const allStepConvertedFlatten = flatten(clonedFlow.steps, splitKey);

  Object.keys(allStepConvertedFlatten).forEach((key) => {
    const isIncludedAvailablePropForStep = key.includes("actions") && !key.includes("translations") && key.includes("actionDefinitions");
    const isIncludedAvailablePropForCondition = key.includes("filter");
    // this line added for this key [{"name":"/0/#1","type":"output","function":""}]
    const isIncludedAvailableOutput = typeof allStepConvertedFlatten[key] === "string" && (allStepConvertedFlatten[key].includes('"output"') || allStepConvertedFlatten[key].includes('"metaoutput"'));
    if ((isIncludedAvailablePropForStep || isIncludedAvailablePropForCondition) && isIncludedAvailableOutput) {
      const definition = JSON.parse(allStepConvertedFlatten[key]);
      // eslint-disable-next-line consistent-return
      definition.forEach((input, index) => {
        if (input.type !== "output" && input.type !== "metaoutput") {
          return input;
        }
        const inputPosition = getInputPosition(input);
        if (changedStep && changedStep?.id) {
          const splitter = isIncludedAvailablePropForStep ? "actions" : "filter";
          allStepConvertedFlatten[key] = JSON.stringify(syncInputData({
            input,
            changedStep,
            step: {
              ix: findIxForStepOrConditionInFlattenDataByKey(allStepConvertedFlatten, key, splitter, splitKey),
              iy: findIyForStepOrConditionInFlattenDataByKey(allStepConvertedFlatten, key, splitter, splitKey),
              uniqueId: findUniqueIdForStepOrConditionInFlattenDataByKey(allStepConvertedFlatten, key, splitter, splitKey),
            },
            definition,
            inputPosition,
            inputIndex: index,
          }));
        } else if (removedStep && removedStep?.id) {
          const splitter = isIncludedAvailablePropForStep ? "actions" : "filter";
          allStepConvertedFlatten[key] = JSON.stringify(removeInputData({
            input,
            removedStep,
            definition,
            inputPosition,
            inputIndex: index,
            step: {
              ix: findIxForStepOrConditionInFlattenDataByKey(allStepConvertedFlatten, key, splitter, splitKey),
              iy: findIyForStepOrConditionInFlattenDataByKey(allStepConvertedFlatten, key, splitter, splitKey),
              uniqueId: findUniqueIdForStepOrConditionInFlattenDataByKey(allStepConvertedFlatten, key, splitter, splitKey),
            },
          }));
        } else if (addedStep && addedStep?.id) {
          const splitter = isIncludedAvailablePropForStep ? "actions" : "filter";
          allStepConvertedFlatten[key] = JSON.stringify(addInputData({
            input,
            addedStep,
            definition,
            inputPosition,
            inputIndex: index,
            step: {
              ix: findIxForStepOrConditionInFlattenDataByKey(allStepConvertedFlatten, key, splitter, splitKey),
              iy: findIyForStepOrConditionInFlattenDataByKey(allStepConvertedFlatten, key, splitter, splitKey),
              uniqueId: findUniqueIdForStepOrConditionInFlattenDataByKey(allStepConvertedFlatten, key, splitter, splitKey),
            },
          }));
        }
      });
    }
  });

  const newList = unflatten(allStepConvertedFlatten, splitKey);
  return typeof newList === "object" ? Object.values(newList) : newList;
}

function findIxForStepOrConditionInFlattenDataByKey(flattenData, key, splitter, splitKey) {
  return flattenData[`${key.split(splitter)[0].split(splitKey).slice(0, -1).join(splitKey)}${splitKey}ix`];
}
function findIyForStepOrConditionInFlattenDataByKey(flattenData, key, splitter, splitKey) {
  return flattenData[`${key.split(splitter)[0].split(splitKey).slice(0, -1).join(splitKey)}${splitKey}iy`];
}
function findUniqueIdForStepOrConditionInFlattenDataByKey(flattenData, key, splitter, splitKey) {
  return flattenData[`${key.split(splitter)[0].split(splitKey).slice(0, -1).join(splitKey)}${splitKey}uniqueId`];
}
function getUniqueId(uniqueId) {
  return typeof uniqueId === "string" ? uniqueId.split("-") : uniqueId;
}
function updateName(input, oldIndex, newIndex) {
  if (input.type === "metaoutput") {
    return input.name.replace(`#ix-${oldIndex.toString()}`, `#ix-${newIndex.toString()}`);
  }
  return input.name.replace(`/${oldIndex.toString()}/`, `/${newIndex.toString()}/`);
}
function getInputPosition(input: { type: string, name: string }): number {
  if (input.type === "metaoutput") {
    const match = input.name.match(/#ix-(\d+)/);
    return match && match[1] ? parseInt(match[1], 10) : NaN;
  }
  return parseInt(input.name.split("/#")[0].replace("/", ""), 10);
}

function syncInputData({
  input, changedStep, step, definition, inputPosition, inputIndex,
}) {
  if (step.iy === changedStep.step.iy && inputPosition >= step.ix) {
    definition.splice(inputIndex, 1);
  } else if (changedStep.newIndex < changedStep.oldIndex) {
    let stepId = getUniqueId(step.uniqueId);
    const changedStepId = getUniqueId(changedStep.uniqueId);
    if ((inputPosition + 1) > changedStep.newIndex && inputPosition < changedStep.oldIndex) {
      if (typeof changedStepId === "number") {
        definition[inputIndex] = { ...input, name: updateName(input, inputPosition, inputPosition + 1) };
      } else if (stepId.length === changedStepId.length) {
        if (stepId[stepId.length - 2] === changedStepId[changedStepId.length - 2] && parseInt(stepId[stepId.length - 1], 10) >= parseInt(changedStepId[changedStepId.length - 1], 10)) {
          definition[inputIndex] = { ...input, name: updateName(input, inputPosition, inputPosition + 1) };
        }
      } else if (stepId.length > changedStepId.length) {
        stepId = stepId.slice(0, changedStepId.length);
        if (stepId[stepId.length - 2] === changedStepId[changedStepId.length - 2] && parseInt(stepId[stepId.length - 1], 10) >= parseInt(changedStepId[changedStepId.length - 1], 10)) {
          definition[inputIndex] = { ...input, name: updateName(input, inputPosition, inputPosition + 1) };
        }
      }
    } else if (inputPosition === changedStep.oldIndex) {
      if (typeof changedStepId === "number") {
        definition[inputIndex] = { ...input, name: updateName(input, changedStep.oldIndex, changedStep.newIndex) };
      } else if (stepId.length === changedStepId.length) {
        if (stepId[stepId.length - 2] === changedStepId[changedStepId.length - 2] && parseInt(stepId[stepId.length - 1], 10) >= parseInt(changedStepId[changedStepId.length - 1], 10)) {
          definition[inputIndex] = { ...input, name: updateName(input, changedStep.oldIndex, changedStep.newIndex) };
        }
      } else if (stepId.length > changedStepId.length) {
        stepId = stepId.slice(0, changedStepId.length);
        if (stepId[stepId.length - 2] === changedStepId[changedStepId.length - 2] && parseInt(stepId[stepId.length - 1], 10) >= parseInt(changedStepId[changedStepId.length - 1], 10)) {
          definition[inputIndex] = { ...input, name: updateName(input, changedStep.oldIndex, changedStep.newIndex) };
        }
      }
    }
  } else if (changedStep.newIndex > changedStep.oldIndex) {
    let stepId = getUniqueId(step.uniqueId);
    const changedStepId = getUniqueId(changedStep.uniqueId);
    if (changedStep.oldIndex < inputPosition && inputPosition <= changedStep.newIndex) {
      if (typeof changedStepId === "number") {
        definition[inputIndex] = { ...input, name: updateName(input, inputPosition, inputPosition - 1) };
      } else if (stepId.length === changedStepId.length) {
        if (stepId[stepId.length - 2] === changedStepId[changedStepId.length - 2] && parseInt(stepId[stepId.length - 1], 10) >= parseInt(changedStepId[changedStepId.length - 1], 10)) {
          definition[inputIndex] = { ...input, name: updateName(input, inputPosition, inputPosition - 1) };
        }
      } else if (stepId.length > changedStepId.length) {
        stepId = stepId.slice(0, changedStepId.length);
        if (stepId[stepId.length - 2] === changedStepId[changedStepId.length - 2] && parseInt(stepId[stepId.length - 1], 10) >= parseInt(changedStepId[changedStepId.length - 1], 10)) {
          definition[inputIndex] = { ...input, name: updateName(input, inputPosition, inputPosition - 1) };
        }
      }
    } else if (inputPosition === changedStep.oldIndex) {
      if (typeof changedStepId === "number") {
        definition[inputIndex] = { ...input, name: updateName(input, changedStep.oldIndex, changedStep.newIndex) };
      } else if (stepId.length === changedStepId.length) {
        if (stepId[stepId.length - 2] === changedStepId[changedStepId.length - 2] && parseInt(stepId[stepId.length - 1], 10) >= parseInt(changedStepId[changedStepId.length - 1], 10)) {
          definition[inputIndex] = { ...input, name: updateName(input, changedStep.oldIndex, changedStep.newIndex) };
        }
      } else if (stepId.length > changedStepId.length) {
        stepId = stepId.slice(0, changedStepId.length);
        if (stepId[stepId.length - 2] === changedStepId[changedStepId.length - 2] && parseInt(stepId[stepId.length - 1], 10) >= parseInt(changedStepId[changedStepId.length - 1], 10)) {
          definition[inputIndex] = { ...input, name: updateName(input, changedStep.oldIndex, changedStep.newIndex) };
        }
      }
    }
  }

  return definition;
}

function removeInputData({
  input, removedStep, definition, inputPosition, inputIndex, step,
}) {
  let stepId = getUniqueId(step.uniqueId);
  const removedStepId = getUniqueId(removedStep.step.uniqueId);
  if (inputPosition === removedStep.ix && removedStep.iy >= step.iy && stepId[stepId.length - 2] >= removedStep[removedStep.length - 2]) {
    definition.splice(inputIndex, 1);
  } else if (inputPosition > removedStep.ix && removedStep.iy <= step.iy) {
    if (typeof removedStepId === "number") {
      definition[inputIndex] = { ...input, name: updateName(input, inputPosition, inputPosition - 1) };
    } else if (stepId.length === removedStepId.length) {
      if (stepId[stepId.length - 2] === removedStepId[removedStepId.length - 2] && parseInt(stepId[stepId.length - 1], 10) >= parseInt(removedStepId[removedStepId.length - 1], 10)) {
        definition[inputIndex] = { ...input, name: updateName(input, inputPosition, inputPosition - 1) };
      }
    } else if (stepId.length > removedStepId.length) {
      stepId = stepId.slice(0, removedStepId.length);
      if (stepId[stepId.length - 2] === removedStepId[removedStepId.length - 2] && parseInt(stepId[stepId.length - 1], 10) >= parseInt(removedStepId[removedStepId.length - 1], 10)) {
        definition[inputIndex] = { ...input, name: updateName(input, inputPosition, inputPosition - 1) };
      }
    }
  }

  return definition;
}

function addInputData({
  input, addedStep, definition, inputPosition, inputIndex, step,
}) {
  let stepId = getUniqueId(step.uniqueId);
  const addedStepId = getUniqueId(addedStep.uniqueId);
  if (inputPosition >= addedStep.ix && addedStep.iy <= step.iy) {
    if (typeof addedStepId === "number") {
      definition[inputIndex] = { ...input, name: updateName(input, inputPosition, inputPosition + 1) };
    } else if (stepId.length === addedStepId.length) {
      if (stepId[stepId.length - 2] === addedStepId[addedStepId.length - 2] && parseInt(stepId[stepId.length - 1], 10) >= parseInt(addedStepId[addedStepId.length - 1], 10)) {
        definition[inputIndex] = { ...input, name: updateName(input, inputPosition, inputPosition + 1) };
      }
    } else if (stepId.length > addedStepId.length) {
      stepId = stepId.slice(0, addedStepId.length);
      if (stepId[stepId.length - 2] === addedStepId[addedStepId.length - 2] && parseInt(stepId[stepId.length - 1], 10) >= parseInt(addedStepId[addedStepId.length - 1], 10)) {
        definition[inputIndex] = { ...input, name: updateName(input, inputPosition, inputPosition + 1) };
      }
    }
  }

  return definition;
}
